@tailwind base;
@tailwind components;
@tailwind utilities;

/* Message */
.chat-bubble {
  @apply bg-[#0a192f] w-max max-w-[calc(100%_-_50px)] shadow-md flex items-start mb-5 p-[15px] rounded-[20px_20px_20px_0] text-gray-400;
}
.chat-bubble.right {
  @apply bg-gray-400 text-[#0a192f] ml-auto rounded-[20px_20px_0_20px];
}
.chat-bubble__left {
  @apply w-[35px] h-[35px] mr-2.5 rounded-[50%];
}
.user-name {
  @apply font-[bold] text-[0.9rem] text-blue-700 mb-[5px];
}
.user-message {
  @apply break-all;
}
.message-time {
  @apply block text-right;
}
